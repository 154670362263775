<template>
  <main class="main background_group">
    <transition name="slide-right">
      <div class="container_small">
        <div class="content">
          <h1 class="label">
            Merci pour votre réponse !
          </h1>
          <p class="thankyou" style="margin-top: 50px">Plus vous serez nombreux à voter pour votre magasin Intermarché préféré, plus il y a de chance que ce service débarque chez vous !</p>
          <p class="thankyou">Parlez-en autour de vous !</p>
          <router-link :to="{ name: 'Home'}" class="btn bgc_red" >
          Retour
          </router-link>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import axios from 'axios';
import { gTag, ahoyTag, domain } from '@store_front/utils/helper';

export default {
  mounted(){
    gTag.groupView(`Votre réponse ${domain()}`, 'Merci')

    axios.get('/api/v2/store_groups/current')
    .then(({ data })=>{
      this.group = data

      let trackParams = { "group": this.group.name }
      ahoyTag.track("Viewed thank you page", trackParams)
    });
  },
}
</script>

<style scoped>
.thankyou {
  font-size: 20px;
  padding-top: 20px;
}
</style>