import Home from './index';

const routes = [
  {
    path: '/',
    name: "Home",
    component: Home
  }
]


export default routes;
