import TellUs from './index';
import ThankYou from './thank_you';
const routes = [
  {
    path: '/tell_us',
    name: "TellUs",
    component: TellUs
  },
  {
    path: '/thank_you',
    name: "ThankYou",
    component: ThankYou
  }
]


export default routes;
