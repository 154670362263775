import Vue from 'vue';
import logo from 'store_front_images/logos/logo.svg';
import magasinRedWhite from 'store_group_images/icons/magasin-red-white.svg';
import headerBackground from 'store_group_images/headers/img/mcv-og-1200x630.jpg';
import favicon16 from 'store_group_images/headers/icon/favicon-16x16.png';
import favicon32 from 'store_group_images/headers/icon/favicon-32x32.png';
import mcvlogo from 'store_group_images/headers/icon/favicon.png';
import appleIcon from 'store_group_images/headers/icon/apple-touch-icon.png';
import safariIcon from 'store_group_images/headers/icon/apple-touch-icon.png';

// import magasinRedDark from 'store_group_images/icons/magasin-red-dark.svg';

Vue.mixin({
  data: ()=>{
    return {
      images: {
        logo,
        magasinRedWhite,
        headerBackground,
        favicon16,
        favicon32,
        mcvlogo,
        appleIcon,
        safariIcon
        // magasinRedDark,
      }
    }
  }
})
