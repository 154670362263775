import Vue from 'vue';
import router from 'stores_home/routes';
import App from 'stores_home/app';
import 'stores_home/mixins';

import ahoy from "ahoy.js";
window.ahoy = ahoy;

import VueRouter from 'vue-router';
Vue.use(VueRouter)



document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    router,
    render: h => h(App)
  }).$mount()
  document.body.appendChild(app.$el)
});
