const accentMap = {
  "â":"a",
  "à":"a",
  "ç":"c",
  "é":"e",
  "ê":"e",
  "è":"e",
  "ë":"e",
  "î":"i",
  "ì":"i",
  "ï":"i",
  "ô":"o",
  "ò":"o",
  "û":"u",
  "ù":"u",
  "ü":"u",
};

export const normalize = ( term ) => {
  let ret = "";
  for ( var i = 0; i < term.length; i++ ) {
    ret += accentMap[ term.charAt(i) ] || term.charAt(i);
  }
  return ret;
};


export const subdomain = () => {
  const host = window.location.host.match(/(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i)
  if (!host) return ''
  return host[0].replace('.','')
}

export const domain = () => {
  const host = window.location.host.match(/(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i)
  if (!host) return ''
  return window.location.host.replace(host[0],'')
}

export const protocol = () => {
  return window.location.protocol
}

export const gtag_id = () => {
  return document.querySelector('meta[name="ga-measurement-id"]').getAttribute('content')
}

export const gTag = {
  pageView: (title, pathName) => {
    const subPath = `/stores/${subdomain()}/${pathName}`
    window.gtag('event', 'page_view', {
      page_title: title,
      page_location: `${protocol()}//${domain()}/${subPath}`,
      page_path: subPath,
      send_to: `${gtag_id()}`
    })
  },
  groupView: (title, pathName) => {
    const subPath = `/store_groups/${domain()}/${pathName}`
    window.gtag('event', 'page_view', {
      page_title: title,
      page_location: `${protocol()}//${domain()}/${subPath}`,
      page_path: subPath,
      send_to: `${gtag_id()}`
    })
  },
}

export const ahoyTag = { 
  track: (title, params) => {
    window.ahoy.track(title, params);
  }
}
