<template>
  <main class="main background-grey">
    <transition name="slide-right">
        <div class="content">
          <div class="d-flex col-lg-12 mb-3 px-3">
            <div class="d-flex mr-2 align-items-center">
              <i class="icon"></i>
              <img :src="images.mcvlogo" width="48" />
            </div>
            <div class="flex-column align-items-center ">
              <h2 class="text-mcv mb-0">
                monconseillervin
              </h2>
              <h6 class="text-black mb-3">
                Votre expert vin au quotidien
              </h6>
            </div>
          </div>
          <img src="https://wia-base-images.s3.eu-west-3.amazonaws.com/wia_image.jpg" class="mcv_header_container mb-3"/>
        <div class="container-fluid">
          <div class="title_store mb-2" id="topOnClick">
            Dans quel magasin êtes-vous ?
          </div>
          <div class="col-lg-6 pl-0 pr-0 shadow-sm mb-3">
            <input v-model="searchKey" type="search" @click="scrollTop" class="registration col-lg-12" placeholder="Ville ou code postal" >
          </div>
          <div class="d-flex flex-wrap mb-2">
            <div @click="filterStores" class="btn-filtre mr-2 mb-1 active">
              Tous
            </div>
            <div v-for="store_group in store_groups">
              <div @click="filterStores" class="btn-filtre mr-2 mb-1 border-black">
                {{store_group}}
              </div>
            </div>
          </div>

          <h5 v-if="search.length == 0" class="mt-5 text-center">Aucun magasin...</h5>

          <form ref="form" class="form">
            <div v-for="store in search">
              <input
                type="radio"
                class="Radio_list"
                :id="store.id"
                :value="store"
                @click="openStore(store)"
              />
              <div class="d-flex justify-content-end">
                <div class=" card card_store border-0 mt-2 p-2 justify-content-center">
                  <label :for="store.id" class="mb-0">
                    <div class="d-flex">
                      <div class="d-flex px-3 justify-content-center">
                        <i class="icon"></i>
                        <img :src="store.image_url" width="60" />
                      </div>
                      <div class="d-flex justify-content-center flex-column">
                        <div class="text-black font-weight-bold name_store ">
                          {{store.name}}
                        </div>
                        <div class="text-black text-capitalize type_store ">
                          {{store.group}} ({{store.store_type}})
                        </div>
                        <div class="text-black address_store font-italic ">
                          {{store.address}}
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </form>
          <!-- <div class="previous">
            <p>Vous ne trouvez pas votre magasin ?</p>
            <p>Vous aimeriez y trouver ce Conseiller ?</p>
            <a href="/tell_us" class="btn-more">Dites-le nous !</a>
          </div>
          <div class="blur blur-more"></div> -->
        </div>
        </div>
    </transition>
    <helmet-provider>
        <div>
            <helmet>
                <meta charSet="utf-8" />
                <!-- Primary Meta Tags -->
                <title>Mon Conseiller Vin | Votre expert vin au quotidien</title>
                <meta name="title" content="Mon Conseiller Vin | Votre expert vin au quotidien">
                <meta name="description" content="Accords mets-vins, cadeaux, envies précises … Le Conseiller Digital qui vous aide à trouver la bouteille de vin idéale dans votre magasin.">
                <meta name="theme-color" content="#1F1240">
                <link rel="canonical" href="https://monconseillervin.com" />

                <!-- Open Graph / Facebook -->
                <meta property="og:type" content="website">
                <meta property="og:url" content="https://monconseillervin.com">
                <meta property="og:title" content="Mon Conseiller Vin | Votre expert vin au quotidien">
                <meta property="og:description" content="Accords mets-vins, cadeaux, envies précises … Le Conseiller Digital qui vous aide à trouver la bouteille de vin idéale dans votre magasin.">
                <meta property="og:image" :content="images.headerBackground">

                <!-- Twitter -->
                <meta property="twitter:card" content="summary_large_image">
                <meta property="twitter:url" content="https://monconseillervin.com">
                <meta property="twitter:title" content="Mon Conseiller Vin | Votre expert vin au quotidien">
                <meta property="twitter:description" content="Accords mets-vins, cadeaux, envies précises … Le Conseiller Digital qui vous aide à trouver la bouteille de vin idéale dans votre magasin.">
                <meta property="twitter:image" :content="images.headerBackground">

                <!-- Favicons -->
                <link rel="apple-touch-icon" sizes="180x180" :href="images.appleIcon">
                <link rel="icon" type="image/png" sizes="32x32" :href="images.favicon32">
                <link rel="icon" type="image/png" sizes="16x16" :href="images.favicon16">
                <link rel="mask-icon" :href="images.favicon16" color="#5bbad5">
                <meta name="msapplication-TileColor" content="#da532c">
            </helmet>
        </div>
    </helmet-provider>
  </main>
</template>

<script>
import axios from 'axios';
import { gTag, ahoyTag, domain } from '@store_front/utils/helper';
import { Helmet, HelmetProvider } from "@jnields/vue-helmet";

export default {
  data(){
    return {
      group: {},
      stores: [],
      store_groups: [],
      searchKey: "",
      stores_buffer: []
    }
  },
  computed: {
    search() {
      return this.stores.filter((store) =>{
        return this.normalizeString(store.name).includes(this.normalizeString(this.searchKey)) || store.zipcode.includes(this.searchKey)
      })
    }
  },
  components: { HelmetProvider, Helmet },
  mounted(){
    gTag.groupView(`Home ${domain()}`, 'Home')

    axios.get('/api/v2/store_groups/current')
    .then(({ data })=>{
      this.group = data
      this.store_groups = data.names
      this.stores = data.stores
      this.stores_buffer = this.stores

      let trackParams = { "group": this.group.name }
      ahoyTag.track("Viewed group front page", trackParams)

      let zipcodes = this.stores.map(store => {
        let matches = store.address.match(/\d{5}/)
        return matches ? matches[0] : ''
      })
      this.stores.forEach(function (store, index) {
        store.zipcode = zipcodes[index]
      })
    });

  },
  methods: {
    openStore(store) {
      let trackParams = { "group": this.group.name, "store": store.name }
      ahoyTag.track("Selected a store", trackParams)

      var front_url = store.front_url
      if (!front_url.match(/^http/i)){
        front_url = window.location.protocol + '//' + front_url
      }
      window.location.replace(front_url)
    },
    filterStores: function (event) {
      // change css
      $('.active').removeClass('active');
      $(event.target).addClass('active');

      // filter stores
      let store_group = event.target.innerText.toLowerCase();

      if (store_group == "tous") {
        this.stores = this.stores_buffer
      } else {
        this.stores = this.stores_buffer.filter((store) =>{
          return store.group.toLowerCase().includes(store_group)
        })
      }
    },
    scrollTop() {
      var topElement = $('#topOnClick')
      var top = topElement.offset().top;
      window.scrollTo(0, top)
    },
    normalizeString(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^\w ]/g, " ").replace(/\s+/g, ' ').trim().toLowerCase();
    }
  }
}
</script>

<style scoped>
.btn-more {
  background: #fff;
  border-radius: 25px;
  padding: 15px;
  color: #000;
  width: fit-content;
  margin-top: 15px;
}

.blur-more {
  backdrop-filter: blur(5.5px);
}

.store-title {
  display: block;
  font-size: 15px;
  margin-bottom: 25px;
}
</style>
