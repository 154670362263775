import VueRouter from 'vue-router';
import HomeRoutes from './home/routes';
import TellUsRoutes from './tell_us/routes';

export default new VueRouter({
  mode: 'history',
  routes: [
    ...HomeRoutes,
    ...TellUsRoutes,
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
