<template>
  <main class="main background_group">
    <transition name="slide-right">
      <div class="container_small">
        <div class="content">
          <h1 class="title">
            Dans quel magasin Intermarché faites-vous vos achats ?
          </h1>
          <form class="form" @submit='submit'>
            <div class="search">
              <div class="ui-widget">
                <input ref="input" id="refInputId"/>
              </div>
            </div>
            <input type="submit" :disabled="!has_answered" value="Valider" class="btn bgc_red adaptor">
          </form>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import axios from 'axios';
import { gTag, ahoyTag, domain } from '@store_front/utils/helper';

export default {
  data(){
    return {
      shop_id: null
    }
  },
  mounted(){
    gTag.groupView(`Dites-le ${domain()}`, 'Dites-le')

    axios.get('/api/v2/store_groups/current')
    .then(({ data })=>{
      this.group = data

      let trackParams = { "group": this.group.name }
      ahoyTag.track("Viewed tell us page", trackParams)
    });

    const autocomplete = this;
    this.$nextTick(()=>{
      $('#refInputId').autocomplete({
        source: function( request, response ) {

          return $.ajax({
            url: "/api/v2/store_groups/autocomplete",
            type: 'POST',
            data: {
              search: request.term,
              authenticity_token: $('meta[name="csrf-token"]').attr('content')
            },
            success: function( resp ) {
              response($.map(resp, function (item) { 
                return {label: item.text, value: item.id}
              }))
            }
          });
        },
        search: function() {
          var term = this.value
          if ( term.length < 3 ) {
            return false;
          }
        },
        focus: function() {
          return false;
        },
        change: ( event, ui )=>{
          if(ui.item) {
            autocomplete.shop_id = ui.item.value;
            autocomplete.shop_name = ui.item.label;
          }
        },
        select: ( event, ui )=>{
          if(ui.item) {
            $('#refInputId').val(ui.item.label)
            autocomplete.shop_id = ui.item.value;
            autocomplete.shop_name = ui.item.label;
          }
          return false
        }
      })
    })
  },
  computed: {
    has_answered(){
      return !!this.shop_id;
    },
  },
  methods: {
    submit(e) {
      e.preventDefault();
      const _this = this

      let trackParams = { "group": this.group.name, "suggestion": this.shop_name }
      ahoyTag.track("Suggested a store", trackParams)

      $.ajax({
        url: "/api/v2/store_groups/tell_us",
        type: 'POST',
        data: {
          shop_id: this.shop_id,
          authenticity_token: $('meta[name="csrf-token"]').attr('content')
        },
        success: function( resp ) {
          _this.$router.push({name: 'ThankYou'})
        }
      });
    },
  }
}
</script>
